.tags {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: var(--scale);
}

.tag {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: var(--darkThistle);
    margin: calc(0.2 * var(--scale)) 0;
    margin-right: var(--scale);
    border-radius: 100vh;
    padding : calc(0.5 * var(--scale));
    padding-left: calc(var(--scale));
}

.tag span {
    margin-right: var(--scale);
    color: white;
}

.tag .icon-container {
    margin: 0;
}
