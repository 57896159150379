#Pricing > h1 {
    margin: calc(2 * var(--scale));
    text-align: center;
}

#Pricing > h2 {
    margin-bottom: calc(5 * var(--scale));
    text-align: center;
    font-size: calc(4 * var(--scale));
    color: var(--darkThistle);
}

.pricing-container {
    display: flex;
    flex-direction: column;    
}

.plan-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin: calc(2 * var(--scale)) 0;
    width: 100%;
}

.pricing-card {
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: calc(2 * var(--scale));
    padding: calc(2 * var(--scale));
    border: solid calc(0.1 * var(--scale)) var(--softGrey);
    width: 80%;
    margin: calc(1 * var(--scale)) 0;
}

.pricing-card h3 {
    font-size: calc(var(--scale) * 3);
}

.pricing-card h4 {
    font-size: calc(var(--scale) * 2);
}

.period-selector {
    margin-top: calc(1 * var(--scale));
}

.period-selector span.selected {
    background-color: orange;
}

.pricing-card .price-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: calc(1 * var(--scale)) 0;
}

.price {
    font-size: calc(var(--scale) * 2);
    font-weight: 600;
}

@media (min-width : 800px), (orientation : landscape) {
    .plan-container {
        flex-direction: row;
        align-items: stretch;
    }

    .pricing-card {
        flex: 1;
        margin: 0 calc(2 * var(--scale));
    }
}
