#Landing {
    display: flex;
    flex-direction: column;
    /* background: var(--softOrange); */
    box-shadow: none;
    width: 100vw;
    min-height: 100%;
}

#Landing h1 {
    color: white;
}

#Landing > h2 {
    margin-left: calc(2 * var(--scale));
    margin-top: calc(10 * var(--scale));
}

/* ------------------ HERO ------------------ */

#Landing .hero-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: calc(100dvh - var(--nav-height));
    padding: calc(3 * var(--scale));
}

#Landing .hero {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;

    --borderRadius: calc(2 * var(--scale));
    --padding : calc(3 * var(--scale));

    background-color: thistle;
    padding: var(--padding);
    border-radius: calc(var(--borderRadius) + var(--padding));
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    height: 100%;
}

#Landing .hero .text {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#Landing .hero h1 {
    color: white;
}

#Landing .hero h2 {
    color: var(--darkThistle);
    /* font-style: italic; */
    font-size: calc(var(--scale) * 2);
}

#Landing .hero .signup {
    background-color: var(--orange);
}

#Landing .hero .signup:hover {
    background-color: orange;
}

#Landing .hero .signup * {
    color: white;
    font-size: calc(var(--scale) * 2);
}


#Landing .hero img {
    width: 100%;
    height: 100%;
    overflow: hidden;
    object-fit: cover;
    object-position: top;
    border-radius: var(--borderRadius);
}


/* ------------------ USE CASES ------------------ */

.use-cases-slider .card {
    background-color: white;
    height: auto;
    justify-content: flex-start;
    align-items: flex-start;
}

.use-cases-slider .card .text {
    display: flex;
    justify-content: flex-start !important;
}

.usecases-container {
    margin: calc(10 * var(--scale)) 0;
}

.use-case {
    display: flex;
    flex-direction: row;
    height: 80%;
    margin: calc(1 * var(--scale));
    padding: calc(2 * var(--scale));
    border-radius: calc(var(--scale));
    background-color: var(--darkThistle);
    /* border: calc(0.1 * var(--scale)) solid var(--darkThistle); */
}

.use-case.special {
    background-color: var(--darkOrange);
    /* border: calc(0.1 * var(--scale)) solid var(--darkOrange); */
}

.use-case.special span {
    color: white;
}

.use-case span {
    color: white ;
    font-size: calc(var(--scale) * 1.5);
    width: 100%;
    text-align: left;
}

.crossed-out {
    text-decoration: line-through;
    margin-right: calc(0.5 * var(--scale));
}

.personae {
    background-color: var(--backgroundGrey);
    padding: 10% calc(2 * var(--scale)) ;
}

.personae h2 span {
    color: orange;
    font-weight: 600;
}

.personae p {
    font-size: calc(var(--scale) * 1.5);
}

.personae p.description {
    font-size: calc(var(--scale) * 2.5);
    color: var(--grey);
}

.personae h3 {
    font-size: calc(var(--scale) * 2.5);
}

.personae h4 {
    font-size: calc(var(--scale) * 2);
    color: var(--darkThistle);
}

.personae .sections {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 6%;
}

.personae .section {
    --padding : calc(2 * var(--scale));
    --borderRadius: calc(1 * var(--scale));

    display: flex;
    flex-direction: column;
    margin: calc(2 * var(--scale));
    padding: var(--padding);
    border-radius: calc(var(--borderRadius) + var(--padding));

    background-color: white;
    width: 80%;
}

.personae .section .feature {
    margin: calc(1 * var(--scale)) 0;
    padding: calc(1 * var(--scale));
    border-radius: var(--borderRadius);
    border: solid calc(0.1 * var(--scale)) var(--softGrey);
}

.personae .feature:nth-last-child(1) {
    margin-bottom: 0;
}

.partnerships {
    margin: calc(10*var(--scale)) 10%;
    border-radius: calc(2 * var(--scale));
    padding: calc(2 * var(--scale));
    background-color: thistle;
}

.partnerships h2 {
    color: var(--darkThistle);
}

.partnerships img {
    width: calc(20 * var(--scale));
    background-color: white;
    padding: calc(1 * var(--scale)) calc(2 * var(--scale));
    border-radius: calc(1 * var(--scale));
}

.partnerships-slider {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin: calc(5 * var(--scale)) 0;
}

/* ------------------ TUTO ------------------ */

.tuto {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: space-between; */
    padding: calc(3 * var(--scale));
    margin-top: calc(20 * var(--scale));
    margin-bottom: calc(10 * var(--scale));

    background: linear-gradient(to bottom, thistle, white);
}

.tuto .steps {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(var(--steps) * 4rem);
}

.tuto .step {
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: white;
    border-radius: calc(2 * var(--scale));
    padding: calc(2 * var(--scale));
    outline: calc(0.1 * var(--scale)) solid var(--transparentGrey);
    margin: 0;
}

.tuto .step:not(:last-child) {
    margin-bottom: calc(0.5 * var(--scale));
}

.tuto .step.unselected {
    background-color: var(--backgroundGrey);
    color: var(--softGrey);
    height: 3rem;
    transition: height 0.3s ease-in-out;
}

.tuto .step.selected {
    height: 5rem;
    transition: height 0.5s ease-in-out;
}

.tuto .illustration {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;
    margin-top: calc(2 * var(--scale));
    width: 100%;
    height: 40%;
}

.tuto img {
    border-radius: calc(2 * var(--scale));
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.tuto .step h4 {
    font-size: calc(var(--scale) * 1.5);
}

.tuto .step.step.unselected h4, .tuto.step.unselected p {
    color: var(--grey);
}

.tuto .step p {
    font-size: calc(var(--scale) * 1);
}

.tuto .step.unselected p {
    opacity: 0;
    height: 0;
}

.tuto .step.selected p {
    display: block;
    opacity: 1;
    height: auto;
    transition: opacity 0.7s 0.1s ease-in-out;
}


@media (min-width : 800px), (orientation : landscape) {
    #Landing .hero {
        flex-direction: row;
    }

    #Landing .hero .text {
        padding-right: var(--padding);
    }

    #Landing .hero .signup {
        width: fit-content;
        margin-left: 0;
        padding-left: calc(4 * var(--scale));
        padding-right: calc(4 * var(--scale));
    }

    #Landing .hero img {
        width: 40vw;
        height: 100%;
        overflow: visible;
    }

    .personae .sections {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        margin-top: 6%;
    }

    .personae .section {
        width: 40%;
    }

    /* ------------------ TUTO ------------------ */
    .tuto {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        min-height: calc(var(--steps) * 4rem);
    }

    .tuto .steps {
        width: 50vw;
        height: 100%;
    }

    .tuto .step:not(:last-child) {
        margin-bottom: calc(0.5 * var(--scale));
    }

    .tuto .illustration {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0;
        margin-left: calc(2 * var(--scale));
        width: 40%;
        height: 100%;
        min-height: calc(max(var(--steps) * 4rem, 100%));
    }
    
    .tuto img {
        border-radius: calc(2 * var(--scale));
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
}
