#Vitrine {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 100vw;
    --nav-height: calc(8 * var(--scale));
}

#Vitrine .vitrine-page {
    padding-top: var(--nav-height);
    overflow-y: auto;
}

#Vitrine nav {
    display: flex;
    position: fixed;
    width: 100%;
    z-index: 100;
}

#Vitrine nav .permanent {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 0 calc(2 * var(--scale));
    height: var(--nav-height);
}


#Vitrine nav.expanded {
    align-items: flex-start;
    height: 100vh;
    background-color: aliceblue;
}


nav .nav-button {
    border: none;
}

nav .burger-container {
    display: flex;
    align-items: center;
}

nav .burger-menu {
    display: flex;
    flex-direction: column;
    background-color: white;
    height: 100%;
    width: 100%;
}

.burger-button {
    margin: 0;
    border: none;
    border-radius: 0;
    width: 100%;
    justify-content: flex-start;
    padding-left: calc(2 * var(--scale));
}

.burger-button:hover {
    cursor: pointer;
    background-color: var(--darkThistle);
}

#Vitrine .harmate-logo-container {
    display: flex;
    align-items: center;
    cursor: pointer;
}

#Vitrine .harmate-logo-container span {
    font-size: calc(2.5 * var(--scale));
    font-weight: 300;
    margin-left: calc(0.5 * var(--scale)); 
}

#Vitrine .harmate-logo-container .harmate-logo {
    transform: rotate(-45deg);
    height: calc(3 * var(--scale));
    width: auto;
}

#Vitrine .signin {
    background-color: var(--darkThistle)
}

#Vitrine .signin * {
    color: white;
}
