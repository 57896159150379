.icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 calc(1 * var(--scale));
}

.icon {
    width: calc(2 * var(--scale));
    height: calc(2 * var(--scale));
    vertical-align: -0.15em;
    fill: currentColor;
    overflow: hidden;
}

.button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: fit-content;
    cursor: pointer;
}


.back {
    margin: 0;
    font-size: calc(1.5 * var(--scale));
    background-color: thistle;
    width: fit-content;
}

.back span {
    color: white;
}

.back .icon-container {
    transform: rotate(180deg);
    background-color: transparent;
    margin-left: 0;
}

.foreground-donut {
    transition: stroke-dashoffset 1s ease-in-out;
}


/* ------------- Harmate Logo ------------- */

.harmate-logo {
    width: calc(8 * var(--scale));
}

.harmate-logo .principal {
    fill: none;
    stroke: black;
    stroke-width: 6;
    stroke-linejoin: round;
    stroke-linecap: round;
}

.harmate-logo .secondary {
    fill: black;
}

.harmate-logo .antenna {
    fill: none;
    stroke: black;
    stroke-width: 6;
    stroke-linejoin: round;
    stroke-linecap: round;
}


/* ------------- Information Logo ------------- */

.information-logo {
    width: calc(1.5 * var(--scale));
}

.information-logo * {
    stroke: var(--softGrey);
    stroke-linecap: round;
    stroke-width: calc(0.2 * var(--scale));
    fill: none;
}

/* ------------- AI Writer Logo ------------- */

.ai-writer-logo {
    width: calc(1.5 * var(--scale));

}

.ai-writer-logo > path {
    fill: black;
    fill-rule: evenodd;
    clip-rule: evenodd;
}

/* ------------- Expand Logo ------------- */

.expand-logo {
    width: 100%;
}

.expand-logo path {
    stroke: white;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
}

/* ------------- File Logo ------------- */

.file-logo path {
    fill: var(--limitedGrey);
    stroke-width: 1.5;
}

/* ------------- Loader ------------- */

.loader {
    width: calc(3 * var(--scale));
    height: calc(3 * var(--scale));   
}

.loader path {
    fill: none;
    stroke: var(--limitedGrey);
    stroke-dasharray: 16;
    stroke-dashoffset: 16;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2;
}


/* ------------- InBox Logo ------------- */

.inbox-logo {
    fill: none;
    width: calc(1.5 * var(--scale));
}

.inbox-logo path {
    stroke-linejoin: round;
    stroke-linecap: round;
    stroke-width: 2;
    stroke: var(--limitedGrey);
}


/* ------------- Burger Icon ------------- */

.burger {
    height: calc(3 * var(--scale));
    margin-left: calc(2 * var(--scale));
}
