.input-entries {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.title-input {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: var(--softOrange);
    margin-bottom: var(--scale);
    border-radius: var(--border-raius);
}

.title-input:focus {
    outline: solid calc(0.2 * var(--scale)) thistle;
}

.input-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;

    --padding : calc(0.2 * var(--scale));
    --border-raius: calc(0.5 * var(--scale));

    padding: var(--padding);
    outline: solid calc(0.2 * var(--scale)) thistle;
    border-radius: calc(var(--border-raius) + var(--padding));
}

.input-container:not(:last-child) {
    margin-bottom: var(--scale);
}

.range-container {
    display: flex;
    flex-direction: row;
}

.range-label-container {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.range-label-container > div {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.range-label {
    color: var(--darkGrey);
}

.importance-label {
    --padding: calc(0.5 * var(--scale));
    display: inline-block;
    width: calc(4ch + var(--padding) * 2); /* Fixe la largeur à 3 caractères */
    text-align: right; /* Aligne le texte à droite */
    margin: calc(0.5 * var(--scale));
    padding: calc(0.5 * var(--scale));
    border-radius: calc(0.5 * var(--scale));
    font-size: calc(1.2 * var(--scale));
    border: 1px solid var(--softGrey);
}

.importance-label:focus {
    outline: solid calc(0.2 * var(--scale)) thistle;
}

textarea {
    appearance: none;
    white-space: pre-wrap; /* Permet au texte de revenir à la ligne */
    overflow-wrap: break-word; /* Coupe les mots longs */
    resize: none; /* Empêche le redimensionnement */
}

.input-container textarea {
    width: 100%;
    padding: 0.5rem;
    border: none;
    border-radius: 0.5rem;
    font-size: 1rem;
}

.input-container textarea:focus {
    outline: solid calc(0.2 * var(--scale)) thistle;
}

.input-container input[type="range"] {
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    cursor: pointer;
}

/***** Chrome, Safari, Opera, and Edge Chromium *****/
.input-container input[type="range"]::-webkit-slider-runnable-track {
    display: flex;
    align-items: center;
    background: linear-gradient(90deg, red, blue);
    border-radius: 100vh;
    height: calc(0.8 * var(--scale));
}

/******** Firefox ********/
.input-container input[type="range"]::-moz-range-track {
    display: flex;
    align-items: center;
    background: linear-gradient(90deg, red, blue);
    border-radius: 100vh;
    height: calc(0.8 * var(--scale));
}


/* Personnalisation du curseur (le cercle) pour les navigateurs basés sur Webkit (Chrome, Safari, etc.) */
.input-container input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none; /* Enlève le style par défaut du curseur */
    appearance: none;
    width: calc(1.5 * var(--scale)); /* Largeur du cercle */
    height: calc(1.5 * var(--scale)); /* Hauteur du cercle */
    background: white; /* Couleur du cercle */
    cursor: pointer; /* Change le curseur en main lors du survol */
    border-radius: 50%; /* Rend le cercle bien arrondi */
    box-shadow: 0px 0px 5px rgba(0,0,0,0.5); /* Ajoute une ombre au cercle */
}

/* Personnalisation du curseur pour Firefox */
.input-container input[type="range"]::-moz-range-thumb {
    width: calc(1.5 * var(--scale)); /* Largeur du cercle */
    height: calc(1.5 * var(--scale)); /* Hauteur du cercle */
    background: #4CAF50; /* Couleur du cercle */
    cursor: pointer; /* Change le curseur en main lors du survol */
    border-radius: 50%; /* Rend le cercle bien arrondi */
    box-shadow: 0px 0px 5px rgba(0,0,0,0.5); /* Ajoute une ombre au cercle */
}

.form-input {
    display: flex;
    flex-direction: column;
}

.form-input .text-input {
    border: calc(0.1 * var(--scale)) solid var(--softGrey);
    border-radius: calc(0.5 * var(--scale));
    font-size: calc(var(--scale) * 1.2);
    padding: calc(0.5 * var(--scale));
}

.form-input .text-input:focus {
    outline: none;
    border-color: var(--darkThistle);
}

/* Enlever le style de base */
.custom-select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: calc(0.5 * var(--scale));
    padding: calc(0.5 * var(--scale));
    font-size: calc(var(--scale) * 1.2);
    color: #333;
    cursor: pointer;
    width: 100%;
    box-sizing: border-box;
}
